$primary-color: #007BFF;
$primary-color-alternative: #007BFF;
$secondary-blue-color: #2552cd;
$mid-light-primary-color: #E5F1FF;
$light-primary-color: #8a94a6;
$light-primary-color-two: #e1e4e8;
$white: #ffffff;

$link-color: $secondary-blue-color;
$dark-text-color: $primary-color;
$light-text-color: $light-primary-color;
$light-button-color: #c1c7d0;

$date-picker-selected-cell-color: $secondary-blue-color;
$date-picker-today-cell-color: #172b4d;

$input-field-border: $light-primary-color-two;
$input-field-color: $primary-color-alternative;
$input-field-interact-border: $secondary-blue-color;
$input-field-placeholder: #b0b7c3;

$success-message-color: #66b485;


