.flex {
    display: flex;
}

.justify-content{
    &-center {
        justify-content: center;
    }
    &-start {
        justify-content: start;
    }
    &-end {
        justify-content: end;
    }
    &-space-between {
        justify-content: space-between;
    }
    &-space-around {
        justify-content: space-around;
    }
    &-space-evenly {
        justify-content: space-evenly;
    }
}

.align-items{
    &-center {
        align-items: center;
    }
    &-start {
        align-items: start;
    }
    &-end {
        align-items: end;
    }
}
.top-left{
    width: 75%;
}
.top-right{
    width: 25%;
}

.left{
    width: 70%;
}

.right{
    width: 30%;
}