.custom-spinner {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: auto;

  &__spinner {
    animation: spin 1.5s linear infinite;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    & ~ span {
      color: red;
      margin-left: 1rem;
    }
  }
}
