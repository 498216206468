.terms-overlay {
  @include overlayBackground;
  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    background-color: rgb(37, 36, 36);
    box-shadow: 0 1rem 4rem rgba(#000, 0.2);
    border-radius: 0.8rem;
    overflow: hidden;
    transform: scale(0.5);
    transition: all 0.4s 0.2s;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 5rem 3rem;

    @include respond(mini-mobile) {
      width: 90vw;
    }

    & h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: white;
      text-transform: uppercase;
    }

    & p {
      font-size: 1.3rem;
      text-align: left;
      color: white;
      max-width: 85%;
      margin-top: 1.5rem;
      line-height: 2rem;

      @include respond(mini-mobile) {
        max-width: 95%;
      }
    }
    & > div {
      display: flex;
      justify-content: flex-end;
      font-size: 1.4rem;
      color: white;
      text-transform: uppercase;

      & span {
        font-weight: 600;
        cursor: pointer;
        &:last-child {
          margin-left: 2rem;
        }
      }
    }

    &--show {
      opacity: 1;
      transform: scale(1);
      font-weight: 0;
      animation: zoomInOut 0.4s 0.2s;
    }
  }
}
