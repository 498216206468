.ant-dropdown {
	.ant-dropdown-arrow {
		border-color: $primary-color !important;
	}
}

.ant-dropdown-menu-dark {
	background-color: $primary-color !important;
	border-radius: 5px !important;
	width: 150px;
	.ant-dropdown-menu-item-divider {
		background-color: $primary-color;
	}
	a {
		color: white;
		border-radius: 5px;
		&:hover {
			background-color: rgba(255, 2555, 255, 0.03);
		}
	}
}

.ant-dropdown-trigger {
	padding: 5px;
	border-radius: 5px;
	transition: background 0.2s;

	&.ant-dropdown-open {
		background-color: $primary-color;

		path {
			fill: white;
		}
	}
}
