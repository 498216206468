.program-nav {
	border-color: #e1e4e8;
	background-color: $white;
	.close-icon {
		path {
			transition: all 0.2s;
		}
		&:hover {
			path {
				opacity: 0.6;
			}
		}
	}

	& > * {
		border-color: #e1e4e8;
		border-bottom: solid 1px #e1e4e8;
		height: 50px;
		align-items: center;
	}

	.link-item {
		cursor: pointer;
		color: #8a94a6;
		transition: opacity 0.2s;
		&:hover {
			opacity: 0.85;
		}
		&.active {
			color: #0a1f44;
		}
	}
}

@media screen and (min-width: 768px) {
	.program-nav {
		position: fixed;
		z-index: 2;
		top: 54px;
	}
}
