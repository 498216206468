.submit-button {
  padding: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
  background-color: var(--color-blue-dark);
  outline: none;
  border: none;
  color: white;
  border-radius: 0.8rem;
  margin: auto !important;
  margin-top: 1rem;
  display: block;
  transition: all 0.3s;
  min-height: 5.2rem;
  width: 100%;

  &--tooltip {
    padding: 2.5rem 1.2rem;
    border-radius: 0;
  }

  @include respond(mini-mobile) {
    padding: 1.9rem;
  }

  &:hover {
    background-color: var(--color-blue-dark2);
  }

  &--white {
    background-color: #fff !important;
    border: 2px solid var(--color-gray2);
    color: var(--color-gray2);

    &:hover {
      border: 2px solid var(--color-blue-dark);
      color: var(--color-blue-dark);
    }
  }
}
