.back-arrow {
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding-right: 0.5rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: rgb(228, 228, 240);
  }
}
