.intro-text {
  padding-top: 8rem;
  text-align: left;
  margin-bottom: 3rem;

  & p {
    font-size: 1.35em;
    padding: 0.5em;
  }

  @include respond(mobile) {
    width: 85%;
    padding-top: 0;
    & p {
      font-size: 1.35em;
      padding: 0.5em;
    }
  }

  &__heading {
    color: var(--color-blue-dark);
    font-weight: 800;
    font-size: calc(1.8rem + 1vw);
    @include respond(mini-mobile) {
      font-size: 2.5rem;
    }
  }

  &__sub-heading {
    color: var(--color-sub-heading);
    font-size: 1.5rem;
    font-weight: 500;
    @include respond(mini-mobile) {
      font-size: 1.8rem;
    }
  }
}
