.inputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;

  &__input {
    &__wrapper {
      position: relative;

      & img {
        position: absolute;
        right: 0.5rem;
        bottom: 1rem;
        cursor: pointer;
      }
    }
  }

  & label {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & span {
      margin-left: 1.5rem;
      text-transform: uppercase;
      font-size: 1.1rem;
      color: var(--color-blue-dark4);
      font-weight: 600;

      @include respond(mini-mobile) {
        font-size: 1.4rem;
      }
    }
  }

  & input {
    padding: 0.5rem 0.7rem;
    margin-top: 0.5rem;
    display: block;
    border: none;
    border-bottom: 1px solid #eeeff1;
    font-size: 1.5rem;
    color: rgb(82, 82, 82);
    transition: all 0.3s;

    @include respond(mini-mobile) {
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid var(--color-yellow);
    }
  }

  & select {
    width: 100%;
    border: none;
    border-bottom: 1px solid #eeeff1;
    align-self: center;
    margin-top: 0.5rem;
    padding: 1rem;
    font-size: 1.3rem;

    @include respond(mini-mobile) {
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid var(--color-yellow);
    }
  }
}
