.error-overlay {
  @include overlayBackground;

  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    position: relative;
    transform: scale(1);
    background-color: #fff;
    box-shadow: 0 1rem 4rem rgba(#000, 0.2);
    border-radius: 0.8rem;
    // overflow: hidden;
    transition: all 0.4s 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5rem 3rem;
    width: 40rem;

    @include respond(mini-mobile) {
      width: 70vw;
    }

    & i {
      position: absolute;
      right: 1rem;
      top: 1rem;
      transform: scale(0.8);
      transition: all 0.3s;
      cursor: pointer;
      font-size: 1.5rem;

      &:hover {
        background-color: rgb(228, 228, 240);
      }
    }

    & > img {
      margin-top: 4rem;
    }
    & h2 {
      font-weight: 800;
      font-size: 3rem;
      margin: 2rem 0;
    }

    & p {
      width: 95%;
      text-align: center;
      font-size: 1.3rem;
      color: var(--color-gray);
      margin-bottom: 2rem;
      line-height: 2rem;
    }

    & button {
    }

    &--show {
      opacity: 1;
      transform: scale(1);
      font-weight: 0;
      animation: zoomInOut 0.4s 0.2s;
    }
  }
}
