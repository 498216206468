.top-nav {
	padding-left: 40px;
	padding-top: 10px;
	padding-right: 40px;
	width: 100%;
	height: 10vh;
	background-color: #003896;
    position: fixed;
	color: #fff;
		top: 0;
		z-index: 2;
	.menulogo {
		float: left;
		background-image: url(../../images/icon.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 40px;
		height: 100%;
		width: 50px;
		margin: 0 5px 0 12px;
		height: 100%;
	}
	.menudetails {
		float: left;
		margin-top: 5px;
	
	.menuname {
		padding: 3px 3px 2px 0;
		font-weight: 700;
		font-size: 16px;
		color: #fff !important;
	}
	.menustatus {
		height: 45%;
		color: #fff ;
		padding: 3px 3px 2px 0;
		font-weight: 700;
		font-size: 12px;
		font-family:Montserrat, Sans-Serif;
	}
	.logged-in {
		color: green;
	  }
}
	
	
	.mt10{
		margin-top: 10px !important;
	}
	
	.link-item {
		cursor: pointer;
		color: #334E68;
		transition: opacity 0.2s;
		font-weight: 500 !important;
		margin-left: 50px;
		height: 40px;
		padding-top: 10px;
		&:hover {
			color: #102A43;
			font-weight: 700 !important;
			border-bottom: #007BFF 4px solid;
		}
		&.active {
			color: #102A43;
			font-weight: 700 !important;
			border-bottom: #007BFF 4px solid;
		}
	}

	.link-item-right {
		cursor: pointer;
	}

}

@media screen and (min-width: 768px) {
	.top-nav {
		position: fixed;
		top: 0;
		z-index: 2;
		padding-left: 40px;
		padding-top: 10px;
	}
}






.body{
	background-image: url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg");
	background-position: center;
  background-repeat: no-repeat;
    background-size: cover;
	height: 90vh;
    margin-top: 10vh;
    // height: -moz-calc(100% - 51px);
    // height: -webkit-calc(100% - 51px);
    // height: -o-calc(100% - 51px);
    // height: calc(100% - 51px);
    width: 100%;
    font-size: 15px;
	background-color: #ece5dd;
    display: block;
    padding: 10px;

    .chat-body {
    display: inline-block;
    margin-bottom: 40px;
    height: 75vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .messagediv {
        padding: 5px;
        position: relative;
        display: flex;
        overflow: auto;
        .message{
            background-color: #fff;
            border-radius: 8px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 20px;
            padding-bottom: 10px;
            box-shadow: 0.5px 0.5px 0.5px 0.5px #a3a3a3;
            min-width: 100px;
            position: relative;
			white-space: pre-line;
			box-shadow: 0px 2px 24px #F1F2F4;
            p{
            font-size: 13px;
    color: #000;
    font-weight: 700;
            }
			-webkit-animation: slide 1s forwards;
			-webkit-animation-delay: 5s;
			animation: slide 1s forwards;
			animation-delay: 5s;
			// animation: fade-in 20s;

			// @keyframes fade-in {
			// 	from {
			// 	  opacity: 0;
			// 	}
			// 	to {
			// 	  opacity: 1;
			// 	}
			//   }
        }
		@-webkit-keyframes message {
			100% { left: 0; }
		}
		
		@keyframes message {
			100% { left: 0; }
		}
		
    }
	.mymessagediv {
		padding: 5px;
		position: relative;
		display: flex;
		overflow: auto;
		justify-content: flex-end;
		transition: visibility 0s, opacity 10s linear;
		.mymessage {
			background-color: #dcebc3;
			margin-right: 30px;
			margin-bottom: 6px;
			border-radius: 8px;
			min-width: 270px;
			padding: 15px;
			font-size: 13px;
			display: block;
			white-space: pre-line;
			-webkit-animation-name: addUsermessage;
			-webkit-animation-duration: .5s;
			animation-name: addUsermessage;
			animation-duration: .5s;
			p{
				font-size: 13px;
		color: #000;
		font-weight: 700;
				}
		}
	}
    }

	.bottom-nav {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 10vh;
		padding-left: 20px;
		padding-top: 10px;
		border-radius: 5px;
		width: 100%;
		.chat {
			background-color: #fff;
			border-radius: 5px;
			width: -moz-calc(100% - 80px);
			width: -webkit-calc(100% - 80px);
			width: -o-calc(100% - 80px);
			width: calc(100% - 80px);
			filter: drop-shadow(-2px 2px 2px #a3a3a3);
			-webkit-filter: drop-shadow(rgb(163,163,163) -2px 2px 2px);
			height: 50px;
		float: left;
		.inputbox {
			width: -moz-calc(100% - 103px);
			width: -webkit-calc(100% - 103px);
			width: -o-calc(100% - 103px);
			width: calc(100% - 103px);
			position: relative;
			height: 50px;
			float: left;
		}
		.chatinput {
			width: 99%;
			border: 0;
			outline: none;
			color: #222 !important;
			height: 50px;
			font-size: 14px;
			margin-top: 1px;
			transition: .3s ease;
			background-color: transparent;
			padding-left: 20px;
			padding-top: 15px;
			padding-bottom: 15px;
			font-weight: 700;
		}
	
	}
	.send{
	.sendicon {
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		background-color: #003896;
		border-radius: 50%;
		z-index: 5;
		padding: 3px;
		min-height: 40px;
float: left;
	}
	}
}
	
}


/* SVG spinner icon animation */
.spinner {
	-webkit-animation: rotate 2s linear infinite;
			animation: rotate 2s linear infinite;
	z-index: 2;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;
  }
  .spinner .path {
	stroke: #cccccc;
	stroke-linecap: round;
	-webkit-animation: dash 1.5s ease-in-out infinite;
			animation: dash 1.5s ease-in-out infinite;
  }
  
  @-webkit-keyframes rotate {
	100% {
	  -webkit-transform: rotate(360deg);
			  transform: rotate(360deg);
	}
  }
  
  @keyframes rotate {
	100% {
	  -webkit-transform: rotate(360deg);
			  transform: rotate(360deg);
	}
  }
  @-webkit-keyframes dash {
	0% {
	  stroke-dasharray: 1, 150;
	  stroke-dashoffset: 0;
	}
	50% {
	  stroke-dasharray: 90, 150;
	  stroke-dashoffset: -35;
	}
	100% {
	  stroke-dasharray: 90, 150;
	  stroke-dashoffset: -124;
	}
  }
  @keyframes dash {
	0% {
	  stroke-dasharray: 1, 150;
	  stroke-dashoffset: 0;
	}
	50% {
	  stroke-dasharray: 90, 150;
	  stroke-dashoffset: -35;
	}
	100% {
	  stroke-dasharray: 90, 150;
	  stroke-dashoffset: -124;
	}
  }