.password-control {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  border-bottom: 1 px solid #eeeff1;

  & .hide-show-password {
    cursor: pointer;
    position: absolute;
    width: 15px;
    right: 8px;
    top: 8px;
    bottom: 20px;
  }
}
