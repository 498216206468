.delete-overlay {
  @include overlayBackground;
  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    background-color: #fff;
    box-shadow: 0 1rem 4rem rgba(#000, 0.2);
    border-radius: 0.8rem;
    overflow: hidden;
    transform: scale(0.5);
    transition: all 0.4s 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 3rem;

    @include respond(mini-mobile) {
      padding: 1rem;
      width: 90vw;
    }

    & figure {
      margin-top: 1rem;
      & svg {
        fill: #645649;
        transform: scale(1.7);
      }
    }

    & h2 {
      margin: 1.2rem;
      font-size: 2.2rem;
      font-weight: 600;
      color: rgb(97, 92, 92);
    }

    & p {
      font-size: 1.3rem;
      text-align: center;
    }

    & button {
      font-size: 1.6rem;
      padding: 0.5rem 0.8rem;
      border: none;
      background: none;

      &:first-child {
        margin-right: 2rem;
        color: var(--color-blue-dark);
      }
      &:last-child {
        border-radius: 0.5rem;
        padding: 1rem;
        color: red;
        font-weight: 600;
      }
    }

    &--show {
      opacity: 1;
      transform: scale(1);
      font-weight: 0;
      animation: zoomInOut 0.4s 0.2s;
    }
  }
}
