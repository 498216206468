.side-bar {
    height: 80vh;
    display: flex;
    width: 100%;
    .left{
        background-color: #007BFF;
        padding: 0.95rem 0 0.95rem 0.5rem;
        min-width: 20%;
        box-shadow: 0px 2px 2px #f1f2f4;
       

        .link-item {
            background-color: #007BFF;
            cursor: pointer;
            transition: opacity 0.2s;
            height: 13vh;
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            
            img {
                //width: 50%;
                // display: block;
                // width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 15px;
            }
            
            span{
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 1px;
                color: #FFFFFF;
            }
            // &:hover {
            //     height: 13.3vh;
            //     background-color: #FBFCFE;
            //     border-radius: 5px 0 0 5px;
            //     span{
            //         font-family: Inter;
            //         font-style: normal;
            //         font-weight: 600;
            //         font-size: 11px;
            //         line-height: 1px;
            //         color: #007BFF;
            //     }
            // }
            &.active {
                height: 13vh;
                background-color: #FBFCFE;
                border-radius: 5px 0 0 5px;
                span{
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 1px;
                    color: #007BFF;
                }
            }
        }
    }
    .right{
        background-color: #FBFCFE;
        box-shadow: 0px 2px 2px #f1f2f4;
        min-width: 80%;
    }
}