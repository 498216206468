input,
textarea {
	box-shadow: none !important;
	border-color: $input-field-border !important;
	color: $input-field-color !important;
	&::placeholder {
		color: $input-field-placeholder !important;
	}
	&:hover,
	&:focus,
	&:active {
		border-color: $input-field-interact-border !important;
	}
}

.custom-input {
	input,
	textarea,
	.ant-input-password {
		padding: 5px 14px;
		font-weight: 300;
		border-radius: 5px;
		&:hover,
		&:focus,
		&:active {
			border-color: $input-field-interact-border;
		}
	}

	.ant-input-affix-wrapper {
		border-radius: 5px;
	}
}

.cohort-size-input-field {
	border: solid 1px #d9d9d9;
	border-radius: 5px;
	padding: 12px 16px;
}

.ant-form-item {
	.ant-form-item-label > label {
		color: $dark-text-color;
		font-weight: 400;
	}
}

.charge-type-radio,
.cohort-type-radio {
	.ant-radio-button-wrapper {
		border-left-width: 1px !important;

		&.ant-radio-button-wrapper-checked {
			border-color: #b0b7c3 !important;
		}

		&:not(:first-child)::before {
			content: "";
			width: 0;
			height: 0;
		}

		.content {
			.text {
				color: $light-primary-color;
				&.selected-label {
					color: $primary-color;
				}
			}
		}
	}
}

.charge-type-radio {
	.ant-radio-button-wrapper {
		.content {
			width: 140px;
		}
	}
}

/******  Date Picker *****/

.ant-picker-panel-container {
	background-color: $primary-color !important;
	border-radius: 5px !important;
}

.ant-picker-panel .ant-picker-footer {
	border-top-color: rgba(255, 255, 255, 0.15) !important;
}

.ant-picker-cell {
	.ant-picker-cell-inner {
		color: $white;
		transition: none;
	}
}

.ant-picker-cell-disabled {
	&::before {
		background-color: transparent !important;
	}
	.ant-picker-cell-inner {
		background-color: transparent;
		color: transparentize($color: $white, $amount: 0.7) !important;
	}
}

.ant-picker-content {
	th {
		color: transparentize($color: $white, $amount: 0.7) !important;
	}
}

.ant-picker-header {
	border-bottom-color: rgba(255, 255, 255, 0.15) !important;
}

.ant-picker-prev-icon,
.ant-picker-super-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-next-icon {
	&::before,
	&::after {
		color: $white;
	}
}

.ant-picker-header-view {
	color: $white;
}

.ant-picker-cell:hover {
	.ant-picker-cell-inner:hover {
		background-color: $white;
		color: $primary-color;
	}
}
.ant-picker-cell-selected,
.ant-picker-cell-selected:hover {
	.ant-picker-cell-inner,
	.ant-picker-cell-inner:hover {
		color: $white;
		background-color: $date-picker-selected-cell-color !important;
	}
}

.ant-picker-cell-today {
	.ant-picker-cell-inner {
		background-color: rgba(56, 88, 144, 0.56) !important;
		color: #ffffff;
	}

	.ant-picker-cell-inner::before {
		border: none !important;
	}

	&:hover {
		.ant-picker-cell-inner:hover {
			color: $white;
		}
	}
}

.ant-picker-year-panel,
.ant-picker-decade-panel {
	.ant-picker-cell-disabled .ant-picker-cell-inner {
		background-color: transparent !important;
	}
}

.ant-switch-checked {
	background-color: $primary-color !important;
}

@media screen and (max-width: 768px) {
	.charge-type-radio {
		.ant-radio-button-wrapper {
			.content {
				width: 120px;
			}
		}
	}
}
