.feedback-overlay {
  @include overlayBackground;
  z-index: 100;

  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    position: relative;
    transform: scale(1);
    background-color: #fff;
    box-shadow: 0 1rem 4rem rgba(#000, 0.2);
    border-radius: 0.8rem;
    overflow: hidden;
    transition: all 0.4s 0.2s;
    display: flex;
    flex-direction: column;
    max-width: 45rem;

    @include respond(mini-mobile) {
      width: 70vw;
    }

    & i {
      position: absolute;
      right: 1rem;
      top: 1rem;
      transform: scale(0.8);
      transition: all 0.3s;
      padding: 0.5rem;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 84, 0.946);
      }
    }

    &__header {
      background-color: var(--color-blue-dark);
      padding: 2.5rem 2rem;
      display: flex;
      flex-direction: column;
      text-align: left;

      & h2 {
        font-size: 2rem !important;
        margin-top: 1.5rem;
        color: white;
      }

      & p {
        max-width: 70%;
        font-size: 1.2rem !important;
        line-height: 2rem;
        margin: 0 !important;
        margin-top: 1.5rem;
        color: white !important;
        align-self: flex-start !important;
      }
    }

    &__star-rating {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      align-items: flex-start;

      & h3 {
        color: var(--color-gray2);
      }
    }

    &__text {
      border: 1px solid var(--color-border);
      padding: 1.5rem;
      font-size: 1.3rem;
      resize: none;

      &:focus {
        outline: none;
      }
    }

    &__radio {
      display: flex;
      align-items: center;
      font-size: 1.3rem;

      & label {
        margin: 0;
        margin-left: 0.5rem;
        color: var(--color-gray2);
      }

      &:not(:first-child) {
        margin-left: 1rem;
      }
    }

    &__bottom {
      padding: 3rem 2.5rem;
      &__options {
        display: flex;
        margin-bottom: 3rem;
      }
    }

    &--show {
      opacity: 1;
      transform: scale(1);
      animation: zoomInOut 0.4s 0.2s;
    }
  }
}
