.busy-overlay {
  display: none;

  &--show {
    position: fixed;
    z-index: 100000000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(rgb(255, 255, 255), 0.6);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
