.payment-instructions {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  
  .img {
    width: 20px;
  }
  
  &__details {
    display: flex;
    
    &_text {
      margin-left: 10px;
      font-size: 1.3rem;
      font-weight: 600;
      color: #4a4a4a;
      align-self: center;
    }
    
    &_description {
      margin-top: 0px;
      font-size: 1.3rem;
      font-weight: 400;
      align-self: center;
      
      p {
        margin-left: 30px;
        color: var(--color-gray2);

        span {
          font-weight: 600;
          color: #4a4a4a;
        }
      }
    }
  }

  .padded {
    padding-top: 10px !important;
  }
}