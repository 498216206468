.ant-btn-primary {
	text-shadow: none !important;
}

.ant-btn {
	border: none;
	&:hover {
		opacity: 0.94;
	}
}

.ant-modal-footer {
	.ant-btn {
		width: 80px;
		height: 30px;
		text-align: center;
	}
}

.btn {
	align-items: center;
	border-radius: 8px;
	// & > span.nticon-loading {
	// 	height: 20px;
	// }
	// & > *,
	// svg path {
	// 	color: $white;
	// }

	&.btn-default {
		display: flex;
    align-items: center;
    justify-content: center;
		padding: 13px;
		background-color: $dark-text-color;
		cursor: pointer;
		height: 40px;
		span{
			font-weight: 500 !important;
			font-size: 12px !important;
			color: $white !important;
			}
		&:disabled {
			background-color: $light-button-color;
		}
	}

	&.btn-light {
	display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    height: 40px;
    cursor: pointer;
	background-color: $mid-light-primary-color;
		span{
		font-weight: 500 !important;
		font-size: 12px !important;
		color: $dark-text-color !important;
		}
	}

	&.btn-grey {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50px !important;
		padding: 13px;
		height: 40px;
		cursor: pointer;
		margin-right: 20px;
		background-color: #F0F4F8;
			span{
			font-weight: 500 !important;
			font-size: 12px !important;
			color: #102A43 !important;
			margin-right: 5px;
			}
		}


}



@keyframes turn-over {
	0% {
		transform: rotate(180deg);
	}
	12% {
		transform: rotate(202deg);
	}
	25% {
		transform: rotate(225deg);
	}
	50% {
		transform: rotate(270deg);
	}
	75% {
		transform: rotate(315deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 768px) {
}
