.hidden {
    display: none;
}

.full-height {
    min-height: 100%;
}
.full-screen-height {
    min-height: 100vh;
}

.full-width {
    min-width: 100%;
}
.full-screen-width {
    min-width: 100vw;
}
.partial-height {
    min-height:  80vh;
    padding-top: 10vh;
    padding-bottom: 12vh;
    height: 80vh;
}
.left-width {
    width: 30%;
}

.right-width {
    width: 70%;
    background: #F0F4F8;
}