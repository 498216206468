.pallete{
    background: #F0F4F8;
    

    .left{
width: 15%;
padding: 20px;

.action{
    height: 18vh;
    padding: 15px;
    width: 40px;
    background: #ffffff;
    border-radius: 8px;
    text-align: center;
    img {
        display: block;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        
    }
    p{
        color: #486581;
    }
}
    }

    .center{
        width: 70%;
        display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
    }
    .right{
        width: 15%;
        padding: 20px;

        .sim{
            height: 52vh;
            width: 70%;
            background: #ffffff;
            border-radius: 12px;
            text-align: center;
            .body{
                height: 10vh;
                padding: 10px;
                cursor: pointer;
                &:hover {
                    height: 10vh;
                    border: #007BFF 1px solid;
                    border-radius: 12px;
                }
                &.active {
                    height: 10vh;
                    border: #007BFF 1px solid;
                    border-radius: 12px;
                }
            img {
                display: block;
                width: 60%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 5px;
            }
            p{
                color: #486581;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.02em;
                text-transform: uppercase;
            }
        }
    }
    .zoom{
        display: flex;
        margin-top: 10px;
        width: 70%;

        .icons{
            width: 45%;
            margin-right: 5%;
            background: #FBFCFE;
            height: 50px;
            border-radius: 5px;
           img{
               margin-left: 12px;
               margin-top: 15px;
           }
        }
    }
    }
}