*:not(path, svg) {
	color: $dark-text-color;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}

[role$="alert"] {
	color: currentColor;
}

b {
	font-weight: 600;
}

.text-light {
	color: $light-text-color;
}

.text-semi-light {
	color: $mid-light-primary-color;
}

.ant-spin-dot {
	font-size: 12px;
}
