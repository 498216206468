.tabs {
  @include dashboardWrapper;
  align-items: center;
  margin: 0;
  margin-top: 2rem;

  @include respond(tablet) {
    width: 100%;
  }
  &--stack {
    transform: translateY(-15rem);
  }
  &__heading {
    display: flex;
    color: var(--color-gray-light);
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    border-bottom: 2px solid var(--color-gray-light);

    & > div {
      padding: 1.5rem 0;
      cursor: pointer;
      position: relative;
      transition: all 0.3s;
      min-width: 15rem;

      &:hover {
        color: var(--color-blue-dark);
      }

      &.active {
        color: var(--color-blue-dark);

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          background-color: var(--color-yellow);
          z-index: 1;
          left: 0;
          bottom: 0;
          bottom: -0.2rem;
        }
      }
    }

    &--imports {
      justify-content: center;
      border-bottom: 2px solid #f4f4f4;

      & > div {
        display: flex;
        justify-content: center;
      }

      & > div:first-child {
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }
}
