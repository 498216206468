.empty-list {
  @include dashboardWrapper;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    text-align: center;
    margin: 2rem;
    width: 50%;
    min-width: 35rem;
    align-self: center;

    & p {
      line-height: 2rem;
    }
  }
}
