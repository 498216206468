.address-input {
  display: flex;
  flex-direction: column;

  & input[type='checkbox'] {
    display: none;

    &:checked {
      & ~ label ul {
        transform: scaleY(1);
      }
    }
  }

  & label {
    position: relative;

    & ul {
      background-color: #fff;
      position: absolute;
      width: 100%;
      border: 1px solid var(--color-border);
      list-style: none;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      transform: scaleY(0);
      transform-origin: top;
      max-height: 20rem;
      overflow-y: hidden;
      z-index: 10;

      & span {
        color: var(--color-blue-dark);
        font-weight: 600;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
      }

      & li {
        padding: 0.7rem;
        font-size: 1.3rem;
        &:not(:last-child) {
          border-bottom: 1px solid var(--color-border);
        }

        &:hover {
          background-color: rgb(248, 246, 246);
        }

        & svg {
          fill: gray;
          transform: scale(0.6);
        }
      }
    }
  }
}
