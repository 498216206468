.ant-message-custom-content {
	display: flex;
	align-items: center;
}

.ant-message-notice-content {
	border-left: solid 3px $success-message-color;
	border-radius: 5px !important;
}

.ant-message-success {
	.anticon-check-circle path {
		fill: $success-message-color;
	}
}
