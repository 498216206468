.drop-search {
  position: relative;
  width: 100%;

  & > input {
    display: none;
  }
  & label {
    width: 100%;
    margin: 0;

    & svg {
      transform: scale(0.5);
      transition: all 0.3s;
    }
  }

  & > input:checked {
    & ~ label div:nth-child(2) {
      transform: scaleY(1);
    }

    & ~ label svg {
      transform: rotate(180deg) scale(0.5);
    }
  }
  &__display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-headings);
    cursor: pointer;
    border-bottom: 1px solid var(--color-border);

    & i {
      background-color: unset;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    top: 3.3rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 1px 1px #ededed93;
    border: 1px solid var(--color-border);
    z-index: 100;
    border-top: 1px solid var(--color-border);
    transform-origin: top;
    transition: all 0.3s;

    &:focus-within {
      border-top: 1px solid var(--color-yellow);
    }

    transform: scaleY(0);

    &__search-box {
      width: 100%;
      border: 1px solid var(--color-border) !important;
      align-self: center;
      height: 3rem;
      margin-top: 0.5rem;
      padding: 1rem;
      border-radius: 0.3rem;
      font-size: 1.3rem;

      @include respond(mini-mobile) {
        padding: 1.5rem;
        font-size: 16px;
      }

      &:focus {
        outline: none;
      }
    }

    &__list {
      list-style: none;
      padding: 1rem 0 0 0;
      max-height: 10rem;
      overflow-y: scroll;
      margin-top: 0.5rem;

      & li {
        padding: 1rem 0.7rem;

        @include respond(mini-mobile) {
          font-size: 1.8rem;
        }
      }
    }

    &__item {
      padding: 1rem 0rem;
      cursor: pointer;
      color: var(--color-headings);
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      &:hover {
        background-color: rgba(224, 224, 224, 0.282);
      }
      & img {
        width: 2.5rem;
        margin: 0 1rem;
      }

      &--category {
        display: flex;
        align-items: center !important;

        &:hover {
          background-color: unset;
        }

        & img {
          width: 2rem;
        }

        & span {
          align-self: center;

          @include respond(mini-mobile) {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ebe8e8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(116, 116, 116);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
