.tool-tip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  height: 100vh;
  width: 100%;
  // background-color: rgba(#000, 0.8);
  display: grid;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(6px);
    background-color: rgba(#000, 0.3);
  }

  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    position: relative;
    transform: scale(1);
    background-color: #f8f8f8;
    // box-shadow: 0 1rem 4rem rgba(#000, 0.2);
    // border-radius: 0.8rem;
    overflow: hidden;
    transition: all 0.4s 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 3rem;
    width: 22vw;
    min-width: 30rem;
    line-height: 2rem;
    font-size: 1.2rem;
    text-align: center;

    @include respond(mini-mobile) {
      width: 50vw;
    }

    & > img {
      transform: scale(1.2);
    }

    & i {
      position: absolute;
      right: 1rem;
      top: 1rem;
      transform: scale(0.8);
      transition: all 0.3s;
      cursor: pointer;
      transform: scale(1.2);

      &:hover {
        background-color: rgb(228, 228, 240);
      }
    }

    & p {
      margin-top: 1.5rem;
    }

    &--show {
      opacity: 1;
      transform: scale(1);
      font-weight: 0;
      animation: zoomInOut 0.4s 0.2s;
    }
  }
}
