@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&display=swap');

@import './styles/abstracts/functions';
@import './styles/abstracts/mixins';
@import './styles/abstracts/variables';

@import './styles/base/animation';
@import './styles/base/base';
@import './styles/base/typography';
@import './styles/base/utilities';

@import './styles/components/header';
@import './styles/components/inputGroup';
@import './styles/components/inputGroup2';
@import './styles/components/submit-button';
@import './styles/components/password-control';
@import './styles/components/button-with-tooltip';
@import './styles/components/intro-text';
@import './styles/components/custom-dropdown';
@import './styles/components/error';
@import './styles/components/custom-spinner';
@import './styles/components/empty-list';
@import './styles/components/busy-overlay';
@import './styles/components/delete-overlay';
@import './styles/components/back-arrow';
@import './styles/components/payment-instructions';
@import './styles/components/otp-overlay';
@import './styles/components/error-overlay';
@import './styles/components/feedback-overlay';
@import './styles/components/terms-overlay';
@import './styles/components/address-input';
@import './styles/components//tabs';
@import './styles/components/tool-tip-overlay';

@import './styles/pages/home';

@import './styles/pages/authentication';

