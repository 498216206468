.header {
  display: flex;
  justify-content: space-between;
  padding: 2rem 4.5rem;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;

  & img[alt='logo'] {
    width: 10rem;
  }

  @include respond(mini-mobile) {
    padding: 2rem 2rem;
  }

  & > div {
    height: 3rem;
    width: 3rem;
    border: none;
    padding: 0;
    align-self: center;
    cursor: pointer;
  }

  &__slide-menu {
    &__content {
      position: fixed;
      left: 0;
      right: 0;
      height: 100vh;
      top: 0;
      background-color: rgba(#000, 0.5);
      z-index: -10;
      display: flex;
      justify-content: flex-end;
      display: none;
      animation: fadeIn 0.5s;

      transition: all 0.8s;
      @supports (-webkit-backdrop-filter: blur(5px)) or
        (backdrop-filter: blur(5px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(3px);
        background-color: rgba(#000, 0.2);
      }
    }
    & input[type='checkbox'] {
      display: none;

      &:checked ~ label {
        background-color: transparent !important;
        &::before {
          transform: rotate(135deg);
        }
        &::after {
          transform: rotate(-135deg);
        }

        & div.header__slide-menu__content {
          display: flex;

          & ul {
            right: 0;
          }
        }
      }
    }

    & label {
      position: absolute;
      display: block;
      height: 2px;
      width: 3rem;
      background-color: var(--color-blue-dark);
      z-index: 100000 !important;
      cursor: pointer;
      transition: all 0.5s;
      top: 3rem;

      &::before,
      &::after {
        content: '';
        display: block;
        height: 2px;
        width: 3rem;
        background-color: var(--color-blue-dark);
        cursor: pointer;
        z-index: 100000 !important;
        transition: all 0.5s;
      }

      &::before {
        transform: translateY(-1rem);
      }
      &::after {
        transform: translateY(1rem);
      }

      & ul {
        position: absolute;
        top: 0;
        background-color: white;
        width: 25vw;
        min-width: 30rem;
        height: 100vh;
        right: -50rem;
        animation: slideInLeft 0.3s ease-in;
        list-style: none;
        padding-top: 2.5rem;

        & a,
        & a:visited,
        & a:link {
          text-decoration: none;
        }
        & a li span {
          // color: var(--color-blue-dark) !important;
        }
        a li.sign-out span {
          color: red !important;
        }

        & li {
          padding: 1.5rem 3rem;

          @include respond(mini-mobile) {
            padding: 1.7rem 3rem;
          }
          & span {
            margin-left: 2rem;
            color: var(--color-gray);
            font-size: 1.4rem;
            font-weight: 600;
            transition: all 0.3s;

            @include respond(mini-mobile) {
              font-size: 1.7rem;
            }
          }
          & svg {
            fill: var(--color-gray);
          }

          &:hover,
          &.active-nav {
            background-color: rgb(225, 225, 225) !important;

            & span {
              margin-left: 3rem;
              color: var(--color-blue-dark);
            }

            & svg {
              fill: var(--color-blue-dark);
            }
          }

          &.active-nav {
            & span {
              margin-left: 2rem;
            }
          }
        }

        & h2 {
          margin-left: 3rem;
          color: var(--color-blue-dark);
          font-weight: 700;
          line-height: 4rem;
          margin-bottom: 5rem;
        }
      }
    }
  }
}
