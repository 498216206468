.error {
  & label {
    & span {
      color: var(--color-red) !important;
    }
  }

  & input {
    border-bottom: 1px solid var(--color-red) !important;
  }

  & small {
    margin: 10px 0px;
    color: var(--color-red);
    font-size: 12px;
  }

  &-payment {
    & input {
      border: 1px solid var(--color-red) !important;
      margin-bottom: 1rem;
    }
  }
}

small.error {
  margin: 10px 0px;
  color: var(--color-red);
  font-size: 12px;
}
