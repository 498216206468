.otp-overlay {
  @include overlayBackground;
  z-index: 100;

  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    position: relative;
    transform: scale(1);
    background-color: #fff;
    box-shadow: 0 1rem 4rem rgba(#000, 0.2);
    border-radius: 0.8rem;
    overflow: hidden;
    transition: all 0.4s 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5rem 3rem;

    @include respond(mini-mobile) {
      width: 70vw;
    }

    & i {
      position: absolute;
      right: 1rem;
      top: 1rem;
      transform: scale(0.8);
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: rgb(228, 228, 240);
      }
    }
    & input {
      margin-top: 2rem;
      border-radius: 1rem;
      border: 1px solid var(--color-gray-light);
      font-size: 2rem;
      width: 4rem !important;
      height: 6rem;
      overflow: visible;
      display: flex;
      justify-content: center;
      align-items: center;
      &:focus {
        outline: none;
        border: 1px solid var(--color-blue-dark);
      }
    }

    & h3 {
      margin-top: 2rem;
    }

    & p {
      font-size: 1.5rem;
      color: var(--color-gray);

      &:not(:first-child) {
        margin-top: 1.5rem;
      }

      &.resend {
        margin-bottom: 2rem;
        color: var(--color-blue-dark);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &--show {
      opacity: 1;
      transform: scale(1);
      font-weight: 0;
      animation: zoomInOut 0.4s 0.2s;
    }
  }

  &__timer {
    font-size: 1.2rem;
    color: black;
    font-weight: 700;
  }
}
