.button-tip {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  position: relative;

  & figure {
    margin: 0;
    padding: 0;
    margin-left: 2rem;

    cursor: pointer;
    width: fit-content;
    height: fit-content;
  }

  
}
